import React from 'react';
import './SideProfileButton.css';
import { useNavigate } from 'react-router-dom';

const SideProfileButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/profile');
  };

  return (
    <button className="sidebar-button" onClick={handleClick}>
    <svg className="sidebar-button-icon" fill="var(--text-color)" width="24px" height="24px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
  <g opacity="1">
    <circle cx="128" cy="96" r="64" fill="none"/>
  </g>
  <path d="M231.93652,211.98633a120.48718,120.48718,0,0,0-67.12-54.14258,72,72,0,1,0-73.633,0,120.48821,120.48821,0,0,0-67.11859,54.14062,7.99981,7.99981,0,1,0,13.84863,8.0127,104.0375,104.0375,0,0,1,180.17432.00195,7.99981,7.99981,0,1,0,13.84863-8.01269ZM72,96a56,56,0,1,1,56,56A56.06353,56.06353,0,0,1,72,96Z"/>
</svg>
      <span className="button-text">Profile</span>
    </button>
  );
};

export default SideProfileButton;

