import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './ProfileButton.css';
import { ReactComponent as Icon } from '../Icons/profile.svg'; // Adjust the path as necessary

function ProfileButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/login'); // Navigate to /login route
  };

  return (
    <button className="profile-button" onClick={handleClick}>
      <Icon className="profile-icon" />
    </button>
  );
}

export default ProfileButton;
