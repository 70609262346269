import React from 'react';
import './PathsButton.css';
import { useNavigate } from 'react-router-dom';

const PathsButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/paths');
  };

  return (
    <button className="sidebar-button" onClick={handleClick}>
<svg className="sidebar-button-icon" fill="var(--text-color)" width="24px" height="24px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
  <g opacity="1">
    <circle cx="200" cy="200" r="24"/>
  </g>
  <path d="M200,168a32.05794,32.05794,0,0,0-30.9873,24H72a32,32,0,0,1,0-64h96a40,40,0,0,0,0-80H72a8,8,0,0,0,0,16h96a24,24,0,0,1,0,48H72a48,48,0,0,0,0,96h97.0127A32.0015,32.0015,0,1,0,200,168Zm0,48a16,16,0,1,1,16-16A16.01833,16.01833,0,0,1,200,216Z"/>
</svg>
      <span className="button-text">Paths</span>
    </button>
  );
};

export default PathsButton;
