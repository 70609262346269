import React from 'react';
import './DefNavbar.css';
import DarkModeToggle from './DarkModeToggle';
import ProfileButton from './ProfileButton'

function Navbar() {
  return (
    <div className="navbar">
      <DarkModeToggle/>
      <ProfileButton/>
    </div>
  );
}

export default Navbar;