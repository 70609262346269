import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Sidebar.css';
import HomeButton from './SidebarComponents/HomeButton';
import SideProfileButton from './SidebarComponents/SideProfileButton';
import PathsButton from './SidebarComponents/PathsButton';
import ProButton from './SidebarComponents/ProButton';
import SideSearchbar from './SidebarComponents/SideSearchbar';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(18); // Sidebar width in vw
  const [isResizing, setIsResizing] = useState(false);
  const resizeAnimationFrame = useRef(null);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMouseDown = (e) => {
    if (isCollapsed) return;
    e.preventDefault(); // Prevent text selection
    setIsResizing(true);
  };

  const handleMouseMove = useCallback((e) => {
    if (isResizing) {
      if (resizeAnimationFrame.current) {
        cancelAnimationFrame(resizeAnimationFrame.current);
      }

      resizeAnimationFrame.current = requestAnimationFrame(() => {
        const newWidth = Math.max(11, Math.min(30, (e.clientX / window.innerWidth) * 100));
        setSidebarWidth(newWidth);
      });
    }
  }, [isResizing]);

  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
    if (resizeAnimationFrame.current) {
      cancelAnimationFrame(resizeAnimationFrame.current);
      resizeAnimationFrame.current = null;
    }
  }, []);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.body.style.userSelect = 'none'; // Prevent text selection during resize
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.body.style.userSelect = ''; // Restore text selection
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.body.style.userSelect = ''; // Clean up in case of component unmount
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  return (
    <div className="sidebar-container">
      <div
        className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}
        style={{ width: isCollapsed ? '0px' : `${sidebarWidth}vw` }}
      >
        <div className="sidebar-content">
          <SideSearchbar/>
          <div className='sidebar-button-group'>
          <HomeButton />
          <SideProfileButton />
          <PathsButton />
          <ProButton />
          </div>
          
        </div>
      </div>
      <div className="resize-container">
        <div
          className={`resize-handle ${isCollapsed ? 'no-resize' : ''}`}
          onMouseDown={handleMouseDown}
        />
        <button
          className={`collapse-button ${isCollapsed ? 'collapsed' : ''}`}
          onClick={toggleSidebar}
        >
          {isCollapsed ? (
            <svg className="collapse-icon" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 4.5l7 7-7 7-1.5-1.5 5.5-5.5-5.5-5.5z" fill='var(--bg-color)'/>
            </svg>
          ) : (
            <svg className="expand-icon" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 4.5l-7 7 7 7 1.5-1.5-5.5-5.5 5.5-5.5z" fill='var(--bg-color)'/>
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
