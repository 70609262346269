// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThemeProvider from './Style/ThemeContext';
import Home from './Pages/Home';
import Explore from './Pages/Explore';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import './Style/themes.css';
import './App.css';

const App = () => {
  return (  
    <ThemeProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
