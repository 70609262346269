import React, { useState, useEffect, useMemo } from 'react';
import './SideSearchbar.css';

const SideSearchbar = () => {
  const placeholders = useMemo(() => [
    "Theory of Relativity",
    "Quantum Mechanics",
    "Photosynthesis process",
    "Big Bang Theory",
    "Genetic Code",
    "DNA Replication",
    "Newton's Laws of Motion",
    "Thermodynamics",
    "Evolutionary Biology",
    "Plate Tectonics",
    "String Theory",
    "General Relativity",
    "Black Holes",
    "Dark Matter",
    "Neural Networks",
    "Machine Learning",
    "Cryptography",
    "Artificial Intelligence",
    "Cellular Respiration",
    "Fossil Record",
    "Geological Time Scale",
    "Periodic Table",
    "Chemical Bonding",
    "Acid-Base Reactions",
    "Organic Chemistry",
    "Human Genome Project",
    "Protein Synthesis",
    "Enzyme Kinetics",
    "Mendelian Genetics",
    "Ecosystem Dynamics",
    "Climate Change",
    "Greenhouse Effect",
    "Natural Selection",
    "Carbon Cycle",
    "Hydrologic Cycle",
    "Photosynthetic Pigments",
    "Plant Hormones",
    "Immunology",
    "Virology",
    "Epigenetics",
    "Microbial Ecology",
    "Astrobiology",
    "Cosmic Microwave Background",
    "Galactic Evolution",
    "Stellar Nucleosynthesis",
    "Quantum Entanglement",
    "Superconductivity",
    "Chaos Theory",
    "Fractals",
    "Topology",
    "Calculus",
    "Linear Algebra",
    "Differential Equations",
    "Combinatorics",
    "Graph Theory",
    "Number Theory",
    "Probability Theory",
    "Game Theory",
    "Set Theory",
    "Boolean Algebra",
    "Computational Complexity",
    "Algorithms",
    "Data Structures",
    "Big Data Analytics",
    "Internet of Things",
    "Blockchain Technology",
    "Virtual Reality",
    "Augmented Reality",
    "Quantum Computing",
    "Bioinformatics",
    "Systems Biology",
    "Metagenomics",
    "Genomic Sequencing",
    "Biotechnology",
    "Nanotechnology",
    "Renewable Energy",
    "Solar Power",
    "Wind Energy",
    "Geothermal Energy",
    "Hydroelectric Power",
    "Fossil Fuels",
    "Nuclear Energy",
    "Particle Physics",
    "Standard Model",
    "Boson Particles",
    "Fermion Particles",
    "Supersymmetry",
    "Muon Particles",
    "Neutrino Oscillation",
    "Higgs Boson",
    "Large Hadron Collider",
    "CERN Experiments",
    "Space Exploration",
    "Exoplanets",
    "Astrophysics",
    "Space-time Continuum",
    "Relativistic Physics",
    "Cosmology"
  ], []);
  
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let typingSpeed = isDeleting ? 40 : 50;

    if (!isDeleting && displayText === placeholders[placeholderIndex]) {
      typingSpeed = 2000;
      setTimeout(() => setIsDeleting(true), typingSpeed);
    } else if (isDeleting && displayText === '') {
      setIsDeleting(false);
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }

    const handleTyping = () => {
      setDisplayText((prevText) => {
        if (isDeleting) {
          return prevText.slice(0, -1);
        } else {
          return placeholders[placeholderIndex].slice(0, prevText.length + 1);
        }
      });
    };

    const typingTimer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(typingTimer);
  }, [displayText, isDeleting, placeholders, placeholderIndex]);

  return (
    <div className="sidesearchbar-container">
      <input type="text" className="sidesearchbar" placeholder={displayText} />
    </div>
  );
};

export default SideSearchbar;
