import React, { useState, useRef } from 'react';
import './Signup.css';

const Signup = () => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const inputRefs = useRef([]);

  const handleSignUp = (event) => {
    event.preventDefault();
    setIsCodeSent(true);
  };

  const handleInputChange = (index, event) => {
    if (event.target.value.length === 1 && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <h2>Sign Up</h2>
        {isCodeSent ? (
          <form>
            <div className="input-group">
              <label>Please enter the code sent to your email</label>
              <div className="code-input">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="digit-input"
                    ref={el => inputRefs.current[index] = el}
                    onChange={(e) => handleInputChange(index, e)}
                    required
                  />
                ))}
              </div>
            </div>
            <button type="submit" className="signup-button">Verify</button>
          </form>
        ) : (
          <form onSubmit={handleSignUp}>
            <div className="input-group">
              <label>Email Address</label>
              <input type="email" placeholder="Enter your email" required />
            </div>
            <div className="input-group">
              <label>Password</label>
              <input type="password" placeholder="Enter your password" required />
            </div>
            <div className="input-group">
              <label>Confirm Password</label>
              <input type="password" placeholder="Confirm your password" required />
            </div>
            <button type="submit" className="signup-button">Sign Up</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Signup;


