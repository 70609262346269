import React from 'react';
import Navbar from '../Components/DefNavbar'; 
import E from '../Components/E'; 
import Sidebar from '../Components/Sidebar'
import './Explore.css';

const Explore = () => {
  return (
    <div className="explore-navbar-container">
      <div><E /></div>
      <div><Navbar /></div>
      <div><Sidebar/></div>
</div>
  );
};

export default Explore;